import _filter from 'lodash/filter'
import _forEach from 'lodash/forEach'
import _find from 'lodash/find'

export const setAdditionalProducts = (products) => {
  const additionalProducts = _filter(products, (p) => p.directusParent);

  _forEach(additionalProducts, i => {
   const productTarget = _find(products, product => product.directusId === i.directusParent );
   if(productTarget){
    productTarget.additionalProduct = i;
   }
  })
} 