import Leads from 'src/services/Leads';

export function verifyExistsToken(){

    if (typeof window === 'undefined')  return false;
    
    if(!localStorage.getItem('tokenPortal'))
        Leads.createToken().then(res => 
           window.localStorage.setItem('tokenPortal', `Bearer ${res.data.access_token}`)
        );
}