import React from "react"
import MediaQuery from 'react-responsive';
import CardList from './components/CardList';
import CardListSlider from './components/CardListSlider';

const CardsListSlider = ({ hasArrow, children, ...props  }) => {

  return (
    <>
      <MediaQuery query="(max-width: 1023px)">
        <CardListSlider hasArrow={hasArrow} {...props} >
          {children}
        </CardListSlider>
      </MediaQuery>
      <MediaQuery query="(min-width: 1024px)">
        <CardList {...props}>
          {children}
        </CardList>
      </MediaQuery>
    </>
  )
}

export default CardsListSlider