import React, { useState } from 'react';
import classnames from 'classnames';
import styles from './styles.module.css';

const MenuAcessible = () => {
    const [isFocused, setFocused] = useState(false);

    const containerClasses = classnames(
        "fixed top-0 pv24 ph24 z-999",
        {
            [styles.inFocus]: isFocused,
            [styles.outFocus]: !isFocused,
        },
        styles.container
    );

    const propsButton = {
        className: classnames("br12 w-100 bg-supernova te-papa-green hover-te-papa-green pv7 fw7 ph16", styles.link),
        onFocus: () => setFocused(true),
        onBlur: () => setFocused(false)
    }

    return(
        <div tabIndex={-1} role="navigation" className={containerClasses}>
            <ul className="list ma0 pa0">
                <li className="w-100 ma0 pa0 mb24">
                    <a {...propsButton} href="#conteudo-principal" >
                        Conteúdo principal
                    </a>
                </li>
                <li className="w-100 ma0 pa0">
                    <a {...propsButton} href="#footer" >
                        Rodapé
                    </a>
                </li>
            </ul>
        </div>
    )
}

export default MenuAcessible