import React from "react"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Icon } from '../../../components/Icons';
import classnames from "classnames"
import styles from "../styles.module.css"

const CardListSlider = ({ hasArrow, ...props  }) => {

  if(hasArrow){
    return <CardListArrow {...props} />
  }
  
  return <CardListNoArrow {...props}/>
}

const IconComponent = (props) => {
    const { className, style, onClick, name, ...rest } = props;
    const wrapperIcon = classnames(
      'z-1',
      'arrow-list-card-next',
      className, styles.arrow,
    )
  
    const displayArrow =
      className.includes('slick-next slick-disabled') || className.includes('slick-prev slick-disabled') ? 'none' : 'flex';
  
    const iconClasses = className.includes('slick-next') ? classnames('absolute', styles.customArrowNext) : classnames('absolute', styles.customArrowPrev);
  
    return (
      <div
        className={wrapperIcon}
        style={{
          ...style, display: displayArrow
        }}
        onClick={onClick}
      >
        <Icon name={name} className={iconClasses} width={36} height={36} {...rest} />
      </div>
    );
}

const CardListArrow = ({ children }) => {
  const settingsArrows =  {
    prevArrow: <IconComponent name="ArrowLeft" role="img" aria-label="arrowLeft" aria-labelledby="arrowLeft" title="arrowLeft" />,
    nextArrow: <IconComponent name="ArrowRight" role="img" aria-label="arrowRight" aria-labelledby="arrowRight" title="arrowRight" />,
  }

  const setting = {
    ...settingsArrows,
    variableWidth: true,
    infinite: false,
    swipeToSlide: true,
    slidesToScroll: 1,
    slidesToShow: 2,
    responsive: [
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
        },
      },
    ]
  }

  return (
    <div className="pl16 pl0-ns">
      <Slider {...setting}>
        {children}
      </Slider>
    </div>
  )
}

const CardListNoArrow = ({ children }) => {

  return(
    <div className="flex relative overflow-x-auto pl16 pl0-ns pb16">
      {children}
    </div>
  )
}

export default CardListSlider