import React from "react"
import { Button } from "antd"
import cx from "classnames"
import PropTypes from "prop-types"

const CustomButton = ({ color, text, className, gtmClass, ...rest }) => {
  const btnClasses = cx(`btn-${color}`, className, gtmClass)
  const dataValue = rest["data-value"]
  const dataTrackingId = rest["data-tracking-id"]
  const spanClasses = cx("f16 fw6 lh-title color-inherit", gtmClass)

  return (
    <Button className={btnClasses} type="primary" size="small" {...rest}>
      <span
        className={spanClasses}
        data-value={dataValue}
        data-tracking-id={dataTrackingId}
      >
        {text}
      </span>
    </Button>
  )
}

CustomButton.propTypes = {
  color: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
  gtmClass: PropTypes.string,
}

export default CustomButton
