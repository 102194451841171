import React from 'react';
import classnames from "classnames"
import { Row} from "antd"
import styles from '../styles.module.css';

const CardList = ({ children, className }) => {
    const rowClass = classnames(
      "overflow-x-auto",
      styles.ph8Sm,
      styles.generalHeight,
      styles.lhNormal,
      styles.flexNoWrap,
      className
    )
  
    return (
      <Row
        gutter={[
          { xs: 12, sm: 12, md: 24, lg: 0},
          { xs: 12, sm: 12, md: 24,  lg: 0},
        ]}
        className={rowClass}
      >
        {children}
      </Row>
    )
  }
  
  const IconComponent = (props) => {
    const { className, style, onClick, name, ...rest } = props;
    const wrapperIcon = classnames(
      'z-1',
      'arrow-list-card-next',
      className, styles.arrow,
    )
  
    const displayArrow =
      className.includes('slick-next slick-disabled') || className.includes('slick-prev slick-disabled') ? 'none' : 'flex';
  
    const iconClasses = className.includes('slick-next') ? classnames('absolute', styles.customArrowNext) : classnames('absolute', styles.customArrowPrev);
  
    return (
      <div
        className={wrapperIcon}
        style={{
          ...style, display: displayArrow
        }}
        onClick={onClick}
      >
        <Icon name={name} className={iconClasses} width={36} height={36} {...rest} />
      </div>
    );
  }

export default CardList