import React from 'react';
import { Col } from 'antd';
import { Link } from 'gatsby';

import { Icon } from '../../../components/Icons';

import styles from './styles.module.css';

const Header = ({ children }) => {

  return (
    <div className="mc center">
      <div className="flex flex-row justify-between">
        <Col span={8}
          md={{ span: 8 }}
          lg={{ span: 8 }}>
          <Link to="/" className={styles.outline}>
            <h1 className="ma0 pa0">
              <Icon name="LogoAlgarWhite" className={styles.logoSize} />
              <span className="screen-reader-only">Empresa Digital - Algar Telecom</span>
            </h1>
          </Link>
        </Col>
        {children}
      </div>
    </div>
  )
}

export default Header;