import _get from "lodash/get"
import _map from "lodash/map"
import _keys from 'lodash/keys';
import _find from 'lodash/find';

export default (data) => {
  const [key] = _keys(data);
  const nodes = filterQuery(data)(key);
  const node = _find(nodes, ['status', 'published']);

  return node;
};

const filterQuery = data => tableName => {
  const edges = _get(data, [tableName, 'edges'], []);
  const nodes = _map(edges, 'node');

  return nodes;
};