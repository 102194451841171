import React from 'react';
import classname from 'classnames';
import BlockAttendance from './BlockAttendance';

const HeaderTop = ({ className  }) => {
    const containerClasses = classname('relative pb24 w-100', className);

    return(
        <div className={containerClasses}>
            <BlockAttendance />
        </div>
    )
}

export default HeaderTop;