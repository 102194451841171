import React from "react"
import _get from 'lodash/get'

import CardSolutionsV1 from './components/CardSolutionV1';
import CardSolutionsV2 from './components/CardSolutionV2';

const CardSolutions = (props) => {

  const version = _get(props, 'version', 'v1');  
  const components = {
    'v1': CardSolutionsV1,
    'v2': CardSolutionsV2,
  }

  const Component = components[version];

  return <Component {...props} />

}

export default CardSolutions;

