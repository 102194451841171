export const CLOUD_PLUS = "Cloud Plus"
export const CLOUD_PHONE_PRO = "Cloud Phone Pro"
export const CLOUD_BACKUP = "Cloud Backup"
export const OFFICE_365 = "Office_365"
export const INTERNET_LINK = "Interent link e Anti-DDOs"
export const SD_WAN = "SD-Wan"
export const SOC = "SOC"
export const NOC = "NOC"
export const SMS_SOLUTION = "SMS Solution"
export const NEXT_MOVE_CAR = "Next Move Car"
export const ANTI_DDOS = "Anti-DDOs"
export const OMNI_PRO = "Omni Pro"
export const SMART_CONNECT = "Smart Connect"
export const TEAMS_VOZ = "Teams e Voz"
export const VISION_MDR = "Vision MEDR"
export const ATENDIMENTO_PREMIUN = "Atendimento Premium"
export const CONTROLE_PONTO = "Controle de Ponto"
export const HOSTING = "Hosting"
export const SMART_FI_PRO = "SmartFi Pro"

export const INFRA = [
  CLOUD_PLUS,
  HOSTING, 
  SMART_CONNECT,
  SMART_FI_PRO
]

export const SECURITY = [
  SD_WAN, 
  ANTI_DDOS,
  SOC,
  CLOUD_BACKUP,
  VISION_MDR
]

export const INNOVATION = [
  NOC,
  ATENDIMENTO_PREMIUN
]

export const  FUTURE = [
  OFFICE_365,
  TEAMS_VOZ,
  CONTROLE_PONTO,
]

export const DIGITAL = [NEXT_MOVE_CAR]

export const DEFAULT_PRODUCTS = [
  CLOUD_PLUS, 
  SMS_SOLUTION, 
  CLOUD_PHONE_PRO
]

export const DEFAULT_PRODUCTS_SECONDARY = [
  INTERNET_LINK,
  SD_WAN,
  SOC,
  ANTI_DDOS,
  OFFICE_365,
  NEXT_MOVE_CAR,
  CLOUD_BACKUP,
  OMNI_PRO,
  SMART_CONNECT,
  TEAMS_VOZ
]