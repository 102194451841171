import React from "react"
import classname from "classnames"
import { Row, Col } from "antd"
import CustomButton from "../../CustomButton"
import Header from "../components/Header"

import styles from "./styles.module.css"

import {
  ATTENDANCE
} from "../../../constants/telephone"

const Default = ({ title, subtitle, description, onClick }) => {
  const copyClasses = classname(
    "db f20 fw7-m fw7-l fw4 lh-copy mt12-l mt12-m mb24-l mb24-m mb24-ns w-80-ns w-60 w-auto-ns white",
    styles.description
  )
  const headerClasses = classname("ma0 pa0 mt24-l mt24-m mt12", styles.gradient)
  
  const linkClases = classname(
    "effect-shine white flex justify-end items-center link mb4",
    styles.outline
  )

  return (
    <header className={headerClasses}>
      <Header>
        <div className="flex justify-end">
          <a
            href={`tel:${ATTENDANCE}`}
            className={linkClases}
            target="_blank"
            rel="noreferrer"
            data-value="Atendiemento ao cliente - 0800 942 1220"
            data-tracking-id="CALL_CUSTOMER_SERVICE"
          >
            <div className="ml16-l ml16-m lh-solid lh-title-ns">
              <span
                className="db f14 fw4 link"
                data-value="Atendiemento ao cliente - 0800 942 1220"
                data-tracking-id="CALL_CUSTOMER_SERVICE"
              >
                Atendimento
              </span>
              <span
                className="db f16 fw5 link"
                data-value="Atendiemento ao cliente - 0800 942 1220"
                data-tracking-id="CALL_CUSTOMER_SERVICE"
              >
                {ATTENDANCE}
              </span>
            </div>
          </a>
        </div>
      </Header>
      <div className="mc center pt48 pb32-l pb32-m pb16 pb16-ns pt white">
        <Row align="middle" gutter={{ sm: 24, xs: 0, md: 24, lg: 24, xl: 24 }}>
          <Col sm={24} xs={24} md={13} lg={14} xl={12}>
            <h2 className="ma0 color-inherit lh-title-ns lh-copy f32 f46-m f46-l mb16">
              <span className="db fw6-ns fw7">{title}</span>
              <span className="db harlequin fw3 mt12-l mt12-m">{subtitle}</span>
            </h2>
            <span className={copyClasses}>{description}</span>
            <CustomButton
              text="Falar com Gerente de Soluções"
              color="harlequin"
              className="w-100 w-auto-ns"
              data-value="Falar com Gerente de Soluções"
              gtmClass="GTM_SOLUTIONS_MANAGER_MAIN"
              data-tracking-id="GTM_SOLUTIONS_MANAGER_MAIN"
              onClick={onClick}
            />
          </Col>
        </Row>
      </div>
    </header>
  )
}

export default Default
