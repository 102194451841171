import React from 'react';
import classnames from 'classnames';
import Button from "../../../../components/Button";

const Article = ({ title, subtitle, description,onClick }) => {
    const defaultClasses = classnames('ma0 pa0 white');
    const titleClasses = classnames(defaultClasses, 'white mb12-l f14 f16-l mt24-l mt24-m b fs-normal');
    const subTitleClasses = classnames(defaultClasses, 'f56-l f32 lh-title spring-green b fs-normal')
    const descriptionClasses = classnames(defaultClasses, "f12 f16-l f16-m mt12 normal white")

    return (
        <div className="flex flex-column w-80-l w-80-m pb72">
        
            <h2 className={titleClasses}>
              {title}
            </h2>
            <h3 className={subTitleClasses}>
              {subtitle}
            </h3>
            <span className={descriptionClasses}>
              {description}
            </span>
             <Button
              text="Clique e saiba mais"
              color="springgreen"
              className="mt24 w-40-l w-50 fw6 f16-l f14"
              size="small"
              data-value="Falar com Gerente de Soluções"
              gtmClass="GTM_SOLUTIONS_MANAGER_MAIN"
              data-tracking-id="GTM_SOLUTIONS_MANAGER_MAIN"
              onClick={onClick}
            />
        </div>
    )
}

export default Article;