import React from 'react';
import _map from 'lodash/map';

import { Icon } from '../../../components/Icons';

const socialMedias = [
  {
    icon: "FacebookIcon",
    name: "Facebook Algar",
    url: 'https://www.facebook.com/algartelecom'
  },
  {
    icon: "InstagramIcon",
    name: "Instagram Algar",
    url: "https://www.instagram.com/algartelecom/?hl=pt-br"
  },
  {
    icon: "LinkedinIcon",
    name: "Linkedin Algar",
    url: 'https://www.linkedin.com/company/algar-telecom/?originalSubdomain=pt'
  },
  {
    icon: "TwitterIcon",
    name: "Twitter Algar",
    url: 'https://twitter.com/algartelecom'
  },

  {
    icon: "YoutubeIcon",
    name: "Youtube Algar",
    url: "https://www.youtube.com/channel/UCr_eGd67t50ziCUi6UvhUCA"
  },

]

const SocialMediaItem = ({ icon, name, url, className }) => {

  return (
    <a href={url} target="_blank" className="mr16 link" aria-label={`link para ${name}`}>
      <Icon 
        name={icon} 
        title={name} 
        width={icon !== 'BlogAlgar' ? 32: 247} 
        height={icon !== 'BlogAlgar' ? 32 :43}
      />
    </a>
  )
}

const SocialMedia = () => {

  return (
    <div className="flex flex-column flex-row-ns items-center justify-center">

      <div className="mb16 mb0-ns">
        {
          _map(socialMedias, (media, index) => (
            <SocialMediaItem key={index} {...media} />
          ))
        }
      </div>

      <SocialMediaItem icon="BlogAlgar" name="Blog Algar" url="https://blog.algartelecom.com.br/"/>

    </div>

  )

}

export default SocialMedia;