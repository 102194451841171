import React from 'react';
import { Row, Col } from "antd";
import _get from "lodash/get";
import classnames from 'classnames';
import Article from './components/Article';
import HeaderTop from './components/HeaderTop';
import styles from './styles.module.css';

const HeaderV2 = props => {



  const bannerWrapperClasses = classnames("relative overflow-hidden w-100", styles.banner);

  const Content = props => (
    <div className={`mc center flex justify-start mt24-l mt24-m ${styles.innerContainer}`}>

      <Row align="top" gutter={{ sm: 24, xs: 0, md: 12, lg: 12, xl: 24 }}>
        <HeaderTop />
        <Col sm={24} xs={24} md={12} lg={12} xl={12}>
          <Article {...props} />
        </Col>
      </Row>

    </div>
  )

  const contrastFallback = {
    backgroundColor: _get(props, 'background_color_contrast_fallback', '')
  }

  return (
    <div className={bannerWrapperClasses} style={contrastFallback }>
      <picture>
        <source media="(min-width: 1024px)" srcSet={_get(props, 'large_background.data.full_url', '')} />
        <source media="(min-width: 768px) and (max-width: 1023px)" srcSet={_get(props, 'medium_background.data.full_url', '')} />
        <img
          src={_get(props, 'small_background.data.full_url', '')}
          alt={_get(props, 'title', '')}
          className="absolute z-0 w-100 h-100 top-0 left-0"
          style={{ objectFit: 'cover' }}
        />

      </picture>
      <Content {...props} />
    </div>
  )
}

export default HeaderV2;