import React from 'react';
import classname from 'classnames';
import styles from './headerTop.module.css';

import { ATTENDANCE } from '../../../../constants/telephone';

const BlockAttendance = ({ className }) => {
    const containerClasses = classname('ma0 pa0 white tr', className, styles.hv);

    return (
        <p className={containerClasses}>
            <span className="db f14 fw4">Atendimento</span>
            <span className="db f16 fw7">{ATTENDANCE}</span>
        </p>
    )
}

export default BlockAttendance;