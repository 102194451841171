import React, { 
  Component 
} from "react"
import { Helmet } from "react-helmet"
import queryString from "query-string"
import _get from "lodash/get"
import _map from "lodash/map"
import MediaQuery from 'react-responsive'
import { graphql, StaticQuery } from 'gatsby';
import classnames from 'classnames';
import Button from '../components/Button';
import normalizeData from '../utils/normalizeData';
import TemplateHeader from "../modules/TemplateHeader"
import CategoryTab from "modules/CategoryTab"
import FooterV2 from "../modules/FooterV2"
import Cliente from "../modules/Cliente"
import BlockSolutionsV2 from "../modules/BlockSolutionsV2"
import MenuAcessible from "../modules/MenuAcessible"
import FormLead from "../components/FormLead";
import { getSecundarySolutions } from "../modules/BlockSolutionsV2/util"
import {
  CLASSNAME_TEXT_FEEDBACK_MODAL,
  ID_TEXT_FOOTER_FEEDBACK_MODAL,
} from "../modules/EneBot/staticData/staticVars"
import { formattedNumber } from "../constants/telephone"
import { filterByidentification } from 'src/utils/filterProducts'
import { setAdditionalProducts } from 'src/utils/setAdditionalProducts'
import { 
  INFRA, 
  SECURITY, 
  INNOVATION,
  DEFAULT_PRODUCTS, 
  DEFAULT_PRODUCTS_SECONDARY,
  FUTURE,
  DIGITAL
} from 'src/constants/productList'
import {verifyExistsToken} from 'src/utils/verifyExistToken'
import styles from "./corporativo.module.css"

class LandingCorp extends Component {

  constructor(props) {
    super(props)
    this.state = {
      linked: "",
      productSelected: "",
      whatsappLinkText: formattedNumber,
      iconColorWhatsapp: "#fff",
      selectedSolutions: "",
      chatbotKey: 0,
      open:false,
      product:""
    }
  }

  componentWillMount() {
    if (typeof window === "undefined") return
  
    const parsed = queryString.parse(window.location.search)

    if (parsed.linked !== "") {
      const newConfigs = { ...this.state }
      newConfigs.linked = parsed.linked
      this.setState(newConfigs)
    }
  }


  componentDidMount() {
    if (typeof window === "undefined") return

    this.changeTextContentFeedbackModal()
  }


  changeTextContentFeedbackModal = () => {
    if (!document) return
    const mainTextFeedback = document.getElementsByClassName(
      CLASSNAME_TEXT_FEEDBACK_MODAL
    )
    const footerTextNode = document.getElementById(
      ID_TEXT_FOOTER_FEEDBACK_MODAL
    )

    if (mainTextFeedback && mainTextFeedback.length && mainTextFeedback[0]) 
      mainTextFeedback[0].textContent = "Avalie sua experiência na página!";
    

    if (footerTextNode) 
      footerTextNode.textContent = "Sua opinião é importante para nós!";
  
  }

  setFeatured = name => this.setState({ selectedSolutions: name });
  
  handleShowModal = (data) => this.setState({
    open:true,
    product:data.target.id
  });


  handleCloseModal = () =>  this.setState({
      open:false,
      product:""
  });

  render() {
    const {
      productSelected,
      selectedSolutions,
      open,
      product
    } = this.state

    const { data } = this.props; 
  
    const { 
      title,
      subtitle, 
      description, 
      products,
      clientes,
      id_channel,
      large_background,
      medium_background,
      small_background,
      a11y_background_color_contrast_fallback, 
    } = data;

    setAdditionalProducts(products)

    const cards = {
      infra: filterByidentification(products, INFRA),
      security: filterByidentification(products, SECURITY),
      inovation: filterByidentification(products, INNOVATION),
      future: filterByidentification(products,FUTURE),
      digital: filterByidentification(products,DIGITAL),
      
    }
    const defaultCards = filterByidentification(products, DEFAULT_PRODUCTS)
    const defaultSecondary = filterByidentification(products, DEFAULT_PRODUCTS_SECONDARY)

    const cardActions = { managerSpeach: this.handleOpenChat }

    const mainSolutions = cards[selectedSolutions] || defaultCards
    const secundarySolutions = selectedSolutions ? getSecundarySolutions(cards,selectedSolutions) : defaultSecondary

    const secondTitle = classnames(styles.productTitle, 'te-papa-green tc f20 f46-m f46-l fw7 mb32-l mb32-m mb12 relative');

    const buttonProps = {
      text: "Falar com Gerente de Soluções",
      color: "supernova",
      size: "large",
      className: "w-auto-ns",
      'data-value': "Falar com Gerente de Soluções",
      gtmClass: "GTM_SOLUTIONS_MANAGER_MAIN",
      'data-tracking-id': "GTM_SOLUTIONS_MANAGER_MAIN",
      onClick: this.handleShowModal
    }


    verifyExistsToken();

    return (
      <>
        <Helmet htmlAttributes={{ lang: "pt-BR" }} link={[{
          rel:'canonical',
          href:'https://empresadigital.algartelecom.com.br/'
        }]}>

          {/* OneTrust Cookies Consent Notice start for empresadigital.algartelecom.com.br */}
          <script type="text/javascript" src="https://cdn.cookielaw.org/consent/84d60bff-88c3-4746-99c6-20d19a0b03af/OtAutoBlock.js" ></script>
          <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js" data-language="pt" type="text/javascript" charset="UTF-8" data-domain-script="84d60bff-88c3-4746-99c6-20d19a0b03af" ></script>
          <script type="text/javascript">
          function OptanonWrapper() { }
          </script>
          {/* OneTrust Cookies Consent Notice end for empresadigital.algartelecom.com.br */}
            
          <title>
            Sua empresa digital, fale com consultor agora | Algar Telecom
          </title>
          <meta
            name="description"
            content="Fale com consultor agora, conheça os nossos produtos para fazer a transformação digital da sua empresa. Link dedicado, office 365, proteção contra ataque a site, backup na nuvem, servidor na nuvem"
          />
          <meta charSet="utf-8" />
          <script type="text/javascript" async src="https://d335luupugsy2.cloudfront.net/js/loader-scripts/7ab86a93-a317-4755-ad26-65c618b5778e-loader.js" ></script>
          <body className="montserrat bg-body" />
        </Helmet>
        <main role="main" >
          
          <MenuAcessible />

          <TemplateHeader
            name="version2"
            onClick={this.handleShowModal}
            title={title}
            subtitle={subtitle}
            description={description}
            large_background={large_background}
            medium_background={medium_background}
            small_background={small_background}
            background_color_contrast_fallback={a11y_background_color_contrast_fallback}
          />

            {open &&  
              <FormLead 
                handleCloseModal={this.handleCloseModal}
                id_channel={id_channel}
                product={product}
              /> 
            }
            <CategoryTab
              productSelected={productSelected}
              active={selectedSolutions}
              setFeatured={this.setFeatured}
            />
            <BlockSolutionsV2 
              id="conteudo-principal"
              cardActions={cardActions}
              cardsRows={mainSolutions}
              handleShowModal={this.handleShowModal}
            />
            
            <div className="mc center mt24 mt32-m mt32-l">
            <h2 className={secondTitle}>
              Ainda não encontrou o que precisa?
            </h2>
              <div className="flex justify-center pt24 pt0-l pt0-m">
                <MediaQuery query="(max-width: 1023px)">
                  <Button {...buttonProps} size="small"/>
                </MediaQuery>
                <MediaQuery query="(min-width: 1024px)">
                  <Button {...buttonProps}size="large"/>
                </MediaQuery>
              </div>
              <span className="pa0 ma0 db center te-papa-green tc f32-l f32-m f20 fw4 mt24 mt32-l mt32-m mt24-l mt24-m pb32-l pb16-m mb48 mb48 mb16-l mb16-m mr8">
                Confira outras soluções abaixo
              </span>
            </div>
            <BlockSolutionsV2
              cardActions={cardActions}
              cardsRows={secundarySolutions}
              handleShowModal={this.handleShowModal}
            />

          <Cliente {...{clientes}} />

          <FooterV2  onClick={this.handleShowModal} />
        </main>
      </>
    )
  }
}

export const QueryData = props => (
  <StaticQuery
    query={
      graphql`
      query loadHomeQuery {
        allDirectusLpEmpresaDigital {
          edges {
            node {
              directusId
              title
              subtitle
              description
              status
              id_channel
              a11y_background_color_contrast_fallback
              large_background {
                data {
                  full_url
                }
              }
              medium_background {
                data {
                  full_url
                }
              }
              small_background {
                data {
                  full_url
                }
              }
              products {
                directusId
                identification
                category
                name
                name_market
                title
                description
                video_url
                text_legal
                link_text
                card_size
                button_text
                description_small
                directusParent
                action
                image {
                  data {
                    full_url
                  }
                }
                image_mobile {
                  data {
                    full_url
                  }
                }
                benefits {
                  directusId
                  title
                  icon
                  advantages
                }
              }
              awards {
                directusId
                name
                image {
                  data {
                    full_url
                  }
                }
              }
              clientes {
                directusId
                name
                description
                logo {
                  data{
                    full_url
                  }
                }
                avatar {
                  data{
                    full_url
                  }
                }
              }

            }
          }
        }
      }
    `
    }
    render={data => <LandingCorp {...props} data={normalizeData(data)} />}
  />
);

export default QueryData;