import React from 'react';
import classnames from 'classnames';
import _get from 'lodash/get';
import slugify from 'slugify';


import { Col } from "antd"
import { Link } from "gatsby"
import MediaQuery from 'react-responsive';
import Button from "../../../components/Button"

import styles from './styles.module.css';
import stylesBlock from '../BlockSolutions.module.css'

const CardSolutionsV2 = ({ index, className, handleShowModal, ...props }) => {
    const containerClasses = classnames('bg-white mv16-l mv16-m', styles.card, className);
    const isReverse = index % 2 !== 0
    const innerContainerClasses = classnames(
        'flex w-100 h-100', 
        { 'flex-row-reverse-l flex-row-reverse-m': isReverse },
        );
    
    return (
        <Col className={containerClasses}>
            <div className={innerContainerClasses}>
                <CardInformations {...props} handleShowModal={handleShowModal} />
                <CardImage {...props} />
            </div>
        </Col>
    )
}

const CardImage = ({ image, image_mobile,title }) => {


    const containerClasses = classnames(
        'w-50-l w-50-m h-100-l h-100-m relative-l relative-m absolute bottom-0', 
        styles.blockImg
        );
    
    const propsImg = {
        className: styles.img,
        alt: ''
    }

    return (
        <div className={containerClasses}>
            <MediaQuery query="(max-width: 1023px)">
                <img
                    {...propsImg}
                    src={image_mobile.data.full_url}
                    alt={title}
                />
            </MediaQuery>
            <MediaQuery query="(min-width: 1024px)">
                <img
                    {...propsImg}
                    src={image.data.full_url}
                    alt={title}
                />
            </MediaQuery>
        </div>
    )
}

const CardInformations = ({ 
    name_market: title, 
    description_small, 
    button_text,
    identification,
    image,
    link_text,
    image_mobile,
    handleShowModal,
    ...props
 }) => {
    const backgroundUrl = _get(image, 'data.full_url')
    const imageMobile = _get(image_mobile, 'data.full_url')

    const defaultClasses = classnames("ma0 pa0");
    const titleClasses = classnames(defaultClasses, "te-papa-green f32-l f20 fw7 mb16-l mb16-m mb12 relative", styles.title);
    const descriptionClasses = classnames(defaultClasses, "te-papa-green f14-l fw4 f12 f14-l f14-m mt24-l mt24-m mt12 mb16-l mb16-m m12-ns mb12 fw4");
    const LinkClass = classnames("underline f12", stylesBlock.kelleyGreen, stylesBlock.outline);
    const buttonClass = classnames("mt16-l mt16-m mt12-ns mt12 ph24-l ph16 pv12-l");

    const productProps = {
        imageUrl: backgroundUrl,
        imageMobileUrl: imageMobile,
        identification: identification,
        ...props
    }

    const propsButton = {
        onClick: () => handleShowModal({ target: { id: title } }),
        type: "solid",
        color: "supernova",
        text: button_text,
        className: buttonClass,
        'data-value': `Falar com gerente de soluções - ${identification}`,
        'data-tracking-id': "GTM_SOLUTIONS_MANAGER",
    }  

    return (
        <div className="w-50-l w-50-m flex justify-center mt24 mt0-l mt0-m">
            <div className="flex flex-column justify-center-l justify-center-m w-70-l w-70-m w-90">
                <h3 className={titleClasses}>{title}</h3>
                <span className={descriptionClasses}>{description_small}</span>
                {link_text && <Link
                to={`/${slugify(identification, { lower: true })}`}
                state={productProps}
                className={LinkClass}
                data-tracking-id="GTM_WATCH_VIDEO"
                data-value={`Assista ao vídeo - ${identification}`}>
                {link_text}
                </Link>}
                <div>
                <MediaQuery query="(max-width: 1023px)">
                    <Button
                        {...propsButton}
                        size="small"
                    />
                </MediaQuery>
                <MediaQuery query="(min-width: 1024px)">
                    <Button
                        {...propsButton}
                        size="medium"
                    />
                </MediaQuery>
                </div>
            </div>
        </div>
    )
}

export default CardSolutionsV2;