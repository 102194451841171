import React from 'react';
import styles from './styles.module.css';
import {Col } from 'antd';
import SocialMedia from './components/SocialMedia';
import {Icon} from '../../components/Icons';
import classname  from 'classnames';
import Button from "../../components/Button";

export default  ({onClick}) => {

    const footerClassnames = classname('flex justify-center flex-column items-center',styles.hero);
    const footerH2Classnames = classname(' b montserrat f20  f32-ns fs-normal  tc white',styles.titleh2);
    const footerH3Classnames = classname('ma0 pa0 white montserrat fs-normal-ns tc f12 fw4',styles.titleh3);
    const foooterpclassname = classname('f12 montserrat tc white',styles.footerp);

    return(

        <footer id="footer" className={footerClassnames}>

            <h2 className={footerH2Classnames}>Não encontrou o que procura?</h2>
            <hr className={styles.footerhr} aria-hidden="true" />

            <h3 className={footerH3Classnames}>Temos soluções completas de TI e Telecom para o seu negócio.</h3>

            <Button
                onClick={onClick}
                type="solid"
                color="supernova"
                text="Falar com gerente de soluções"
                size="small"
                className="mv24"
                data-value="Falar com gerente de soluções"
                data-tracking-id="GTM_SOLUTIONS_MANAGER"
            />

            <p className={foooterpclassname}>Fique por dentro de todas nossas novidades e atividades através de nossas redes sociais e também nosso blog:</p>

            <SocialMedia/>

            <Col >
                <div className="white tc flex-ns items-center flex-column-ns flex-row-l justify-center db ph32-m ph0 pb48-l">
                    <div className="flex-ns flex-row-l flex-column items-center">
                        <Icon 
                        name='LogoAlgarWhite' 
                        width={122} height={48} 
                        style={{marginTop:'56px'}} 
                        aria-labelledby="Empresa Digital - Algar Telecom"
                        aria-label="Empresa Digital - Algar Telecom"
                        title="Empresa Digital - Algar Telecom"
                        role="img"
                        />
                    </div>
                </div>
             </Col>
        </footer>

    )
}