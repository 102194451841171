import React from "react"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import _map from "lodash/map"
import _get from "lodash/get"

import classnames from "classnames"

import CardSolution from "./CardSolution"
import CardList from '../CardList'

import styles from "./BlockSolutions.module.css"

const BlockSolutions = ({ cardsRows, cardActions, id,handleShowModal }) => {
  const sectionClass = classnames(
    "mc center",
    "pb24 pb16-m pb16-l", styles.customMC,
    styles.container
  )

  return (
    <section className={sectionClass} id={id}>
        <CardList hasArrow={false} >
          {_map(cardsRows, ({ action, ...item }, index) => (
            <CardSolution
              key={item.title}
              className="dib"
              action={_get(cardActions, action)}
              version="v2"
              index={index}
              {...item}
              handleShowModal={handleShowModal}
            />
          ))}
        </CardList>
    </section>
  )
}

export default BlockSolutions