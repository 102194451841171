import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"

import _map from "lodash/map"
import _size from 'lodash/size'
import _filter from 'lodash/filter'

import Title from "components/Title"
import Button from "components/Button"

import styles from "./styles.module.css"

const CategoryTab = ({ productSelected, active, setFeatured }) => {


  const sectionClass = classnames(
    "mc center",
    "mt32 pt32-l pt32-m pt16 mb16 pb16 pb0-l pb0-m mb24-l mb24-m",
    styles.container
  )

  const titleClass = classnames('pr16 pr0-m pr0-l', styles.title);

  return (
    <section
      className={sectionClass}
      id="Soluções para sua empresa"
      title={productSelected}
    >
      <div className="tc f24 te-papa-green normal pb48 lh-copy ">
        <p className="mb0">
          Montamos jornadas exclusivas para a sua aceleração digital, de acordo com a <br/> 
          necessidade da sua empresa.
        </p>
      </div>
      
      <Title text="Conheça abaixo nossas Jornadas Transformacionais" className={titleClass} />
      <div className="flex items-center justify-center nowrap">
        <div className="relative overflow-auto pb16 pb24-m pb24-l  pr24-ns mt32 pr0-l pr0-m ">
          <Buttons
            buttons={CategoryTab.buttonsProps}
            active={active}
            setFeatured={setFeatured}
          />
        </div>
      </div>
          {FilteredDescription(active)}
      </section>
  )
}

CategoryTab.propTypes = {
  active: PropTypes.string,
  setFeatured: PropTypes.func,
  productSelected: PropTypes.string,
}

CategoryTab.defaultProps = {
  setFeatured: () => {},
}

const FilteredDescription = (active) => {

   const item =   _filter(CategoryTab.buttonsProps, item => item.name === active)
   //CategoryTab.buttonsProps.filter(item => item.name === active);

   if(!_size(item)) return;

   return(
      <div className="tc f16 te-papa-green normal pb48 lh-copy mt48 ">
        <p className="mb0">
          {item[0].description_1} <br/>
          {item[0].description_2}
        </p>  
      </div>
   )
}

const Buttons = ({ buttons, active, setFeatured }) => {
  const getType = isActive => (isActive ? "solid" : "outline")

  return _map(buttons, ({ id, text, name, ...props }) => (
    <Button
      key={`${id} - ${text}`}
      type={getType(active === name)}
      onClick={() => setFeatured(name)}
      text={text}
      color="harlequin"
      {...props}
    />
  ))
}

CategoryTab.buttonsProps = [
  {
    id: 0,
    text: "Infraestrutura de TI",
    name: "infra",
    description_1:"Seu desejo é fazer a transição para uma infraestrutura digital mais segura, escalonável e resiliente?",
    description_2:"Conheça as soluções para apoiar sua empresa!",
    className: "te-papa-green mv4 mr32-l mr32-m mr24 ml4",
    ["data-value"]: "Produtos na nuvem",
    ["data-tracking-id"]: "GTM_CLOUD_PRODUCTS",
  },
  {
    id: 1,
    text: "Segurança",
    name: "security",
    description_1:"Se o seu desejo é afastar riscos de ataques e mais segurança e privacidade para o negócio,",
    description_2:"essa é a solução perfeita! Conheça as soluções para apoiar sua empresa!",
    className: "te-papa-green mv4 mr32-l mr32-m mr24",
    ["data-value"]: "Segurança e estabilidade para minha rede",
    ["data-tracking-id"]: "GTM_SECURITY_STABILITY",
  },
  {
    id: 2,
    text: "Conectividade",
    name: "inovation",
    description_1:"Feito para você que deseja expandir o portfólio e desenvolver produtos,",
    description_2:"serviços e proporcionar experiências abrangentes altamente conectadas. Conheça as soluções para apoiar sua empresa!",
    className: "te-papa-green mv4 mr32-l mr32-m mr24",
    ["data-value"]: "Tendências de mercado / Inovação",
    ["data-tracking-id"]: "GTM_INNOVATION_MARKET",
  },
  {
    id: 3,
    text: "Futuro no trabalho",
    name: "future",
    description_1:"É difícil manter uma equipe integrada, colaborativa e com o mesmo nível de produtividade em um ambiente remoto.",
    description_2:"Para que isso seja possível, temos um portfólio para atender seu negócio. Conheça as soluções para apoiar sua empresa!",
    className: "te-papa-green mv4 mr32-l mr32-m mr24",
    ["data-value"]: "Futuro no trabalho / trabalho",
    ["data-tracking-id"]: "GTM_FUTURE_WORK",
  },
  {
    id: 4,
    text: "Inovação digital",
    name: "digital",
    description_1:"O uso de IoT automatiza processos de forma inteligente por meio da interação entre máquinas, ",
    description_2:"veículos, produtos e ambientes. Conheça as soluções para apoiar sua empresa!",
    className: "te-papa-green mv4 mr4",
    ["data-value"]: "Inovação digital",
    ["data-tracking-id"]: "GTM_DIGITAL",
  },
]

export default CategoryTab
