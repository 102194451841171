import React from 'react'
import classnames from 'classnames';

import PropTypes from "prop-types"

const Title = ({ text, className }) => {
  const classes = classnames('pa0 ma0 mb24-l mb12 f20 f46-m f46-l normal fw7 tc te-papa-green relative', className); 
  return (
  <h2 className={classes}>{text}</h2>
  )
}

Title.propTypes = {
  text: PropTypes.string,
}

export default Title;
