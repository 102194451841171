import React from 'react';
import Default from './Default';
import HeaderV2 from './V2';

const TemplateHeader = (props) => {
  const { name } = props;
  const Component = {
    default: Default,
    version2: HeaderV2
  }

  const HeaderComponent = Component[name];

  return <HeaderComponent {...props} />;
}

export default TemplateHeader;