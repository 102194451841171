import React from 'react';
import styles from './cliente.module.css';
import _map from "lodash/map"
import _get from 'lodash/get'
import { Avatar, Col } from 'antd';
import classname from 'classnames';
import CardListSlider from '../CardList'

const BlockClient =  ({ clientes }) => {
  const titleClasses = classname('ma0 pa0 pl16 pl0-ns pr16 pr0-m pr0-l f20 f46-l f46-m tc fw7 mb32 pb32-l pb32-m te-papa-green');
  const innerContainerCardClasses = classname('flex flex-column items-center justify-center');
  const containerCardDefaultClasses = classname('justify-center items-center relative', styles.containerCard);
  const cardListSlideClasses = classname("overflow-x-scroll justify-center-l justify-center-m", styles.flexNoWrap);
  const dividerClasses = classname('mt16-l mt16-m mb0-l mb0-m', styles.clienteHr)
  const sectionClasses = classname('center mb32 pb32 pt32-l pt32-m pt24', styles.customMC);

  const countClient = clientes.length < 4 ? "flex  items-center justify-center" : "";

  return(
    <section className={sectionClasses}  style={{backgroundColor:'#efefef'}}>
      <h2 className={titleClasses}>
        Saiba o que os clientes Algar têm a dizer. 
        <hr className={dividerClasses}/>
      </h2>
      
      <div className={countClient} >
        <CardListSlider className={cardListSlideClasses} hasArrow={false} >
          {_map(clientes, ({avatar,description,name,logo}, index) => {
            const avatarImageUrl = _get(avatar, 'data.full_url', '');
            const logoUrl = _get(logo, 'data.full_url', '');
            const containerCardClasses = classname(containerCardDefaultClasses, index === 0 ? styles.marginLeftFirst : '');

            return(
              <Col className={containerCardClasses}>
                <div className={innerContainerCardClasses}>
                  <Avatar 
                      className={styles.cardavatar}
                      src={avatarImageUrl}
                      alt=""
                  />
                  <p  className="f12 f16-l f16-m mv12 mt24-l mt24-m mb16-l mb16-m fw4 w-70 tc te-papa-green lh-copy"> {description}</p>
                  <p className="f12 fw7 tc w-70 te-papa-green lh-copy">{name}</p>
                  <img className={styles.logo} alt={name}  src={logoUrl}/>
                </div>
              </Col>
              )}
            )}
          </CardListSlider>
      </div>
    </section>
  )
}

export default BlockClient